<template>
    <Payments/>
</template>
<script>
import Payments from '@/components/Wire/Payments'
export default{
    components:{
        Payments
    }
}
</script>